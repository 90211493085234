import Vue from "vue";
import { Autenticado } from "../services/autenticacao";
import store_site from "../store/store_site"
import { API } from "../services/API"

let obj_user = localStorage.getItem("@YouNect-Imob-" + window.location.hostname + ":user");
function initialState() {
  return {
    store_site        : store_site,
    user              : obj_user,
    email             : localStorage.getItem("@YouNect-Imob-" + window.location.hostname + ":email"),
    cpf               : "",
    autenticado       : false,
    tipo_cliente      : "CLIENTE FINAL",
    menu_itens        : [],
    aplicacao_evento  : [],
    empreend_evento   : [],
    painel_evento     : [],
    relatorio_evento  : [],
  }
}

var usuario = new Vue({

  data () {
    if (!obj_user) {
      obj_user = {}
    }
    else
      obj_user = JSON.parse(obj_user);
    return initialState();
  },

  // Atribui primeiros dados de verificação global do usuário logado
  async created() {
    //let ls_EmpresaSite = await GetEmpresaSite();
    //console.log('EMP SITE:', ls_EmpresaSite)
    // console.log('EMP SITE')
    //this.store_site.cod_empresa = ls_EmpresaSite;
    this.autenticado = await Autenticado();
    // console.log("🚀 ~ file: store_usuario.js:39 ~ created ~ this.autenticado:", this.autenticado)

    if (this.autenticado) {
      // console.log("LOGADO", this.user, this.store_site);
      this.store_site.cod_empresa = this.user.cod_empresa;
    }
    else {
      //this.store_site.cod_empresa = null;
      localStorage.removeItem("@YouNect-Imob-" + window.location.hostname + ":token");
      //console.log("NAO LOGADO", this.user, this.store_site.cod_empresa);
    }

  },

  methods: {
    resetData() { 
      Object.assign(this.$data, initialState());
    },

    // ========== APLICACOES ==========================================================================
    // Busca todos os eventos de todas as aplicacoes
    async busca_aplicacoes() {
      let response2 = [];
      const response = await API.get("/configuracao/aplicacao", {});
      if (response.data.result.length > 0) {
        response2 = response.data.result.map(value => {
          let apps_evento = value.aplicacao_evento.map(value2 => {
            value2.cod_aplicacao_evento   = value2.cod_aplicacao_evento;
            value2.evento_descricao       = value2.evento_nome;
            value2.id_aplicacao           = value.id_aplicacao;
            value2.id_evento              = value2.id_evento;
            value2.evento_cor = '#E9E9E9';
            value2.evento_nome == 'Visualizar' ? value2.evento_cor = '#bbefef' : '#E9E9E9';
            value2.evento_nome == 'Incluir'    ? value2.evento_cor = '#85d7bc' : '#E9E9E9';
            value2.evento_nome == 'Alterar'    ? value2.evento_cor = '#f0cf90' : '#E9E9E9';
            value2.evento_nome == 'Excluir'    ? value2.evento_cor = '#e1acac' : '#E9E9E9';
            value2.evento_nome == 'Liberar'    ? value2.evento_cor = '#f6f6c0' : '#E9E9E9';
            return value2;
          })
          return { descricao: value.nome, cod_aplicacao: value.cod_aplicacao, id_aplicacao: value.id_aplicacao, items: apps_evento};
        })
      }
      return response2;
    },

    // Atribui primeiros dados de verificação global do usuário logado
    priv_busca_privilegios_aplicacao_evento(p_idEvento) {
      // Administrador
      if (this.user.pessoa_funcao.find(o => (o.cod_funcao == 5)))
        return true;

      // Outros Tipos de atribuções
      let p_elemento = null;
      if (this.user.pessoa_privilegio_aplicacao && this.user.pessoa_privilegio_aplicacao.length > 0)
        p_elemento = this.user.pessoa_privilegio_aplicacao.find((o) => (o.id_evento == p_idEvento))
      return p_elemento ? true : false;
    },
  
    // Atribui primeiros dados de verificação global do usuário logado
    priv_seta_privilegios_aplicacao(p_idAplicacao) {
      let p_elementos = this.aplicacao_evento.find((aplicacao) => (aplicacao.id_aplicacao == p_idAplicacao));
      if (!Array.isArray(p_elementos))
        p_elementos = [p_elementos];
      p_elementos.map((aplicacao) => {
        if (aplicacao && aplicacao.items && aplicacao.items.length > 0)
          aplicacao.items.map((evento) => {
            let p_elem = document.querySelectorAll(`.${evento.id_evento}`);
            // Convertendo NodeList em um array
            const elementosArray = Array.from(p_elem);
            if (p_elem) {
              let p_possui_privilegio = this.user.pessoa_privilegio_aplicacao.find((o) => (o.id_evento == evento.id_evento));
              if (!p_possui_privilegio){
                elementosArray.forEach(elemento =>
                  elemento.style.display="none"
                )
              }
            }
          })
      })
    },

    // ========== EMPREENDIMENTOS ==========================================================================
    // Busca todos os eventos de todos os Empreendimentos
    async busca_empreendimentos() {
      let response2 = [];
      const response = await API.get("/configuracao/empreend_evento", {});
      if (response.data.result.length > 0) {
        response2 = response.data.result.map(value => {
          let empreends_evento = value.empreend_evento.map(value2 => {
            value2.cod_empreend_evento   = value2.cod_empreend_evento;
            value2.evento_descricao      = value2.evento_nome;
            value2.id_evento             = value2.id_evento;
            if (!value2.evento_cor)
              value2.evento_cor = '#E9E9E9';
            value2.evento_nome == 'Visualizar' ? value2.evento_cor = '#bbefef' : '#E9E9E9';
            value2.evento_nome == 'Incluir'    ? value2.evento_cor = '#85d7bc' : '#E9E9E9';
            value2.evento_nome == 'Alterar'    ? value2.evento_cor = '#f0cf90' : '#E9E9E9';
            value2.evento_nome == 'Excluir'    ? value2.evento_cor = '#e1acac' : '#E9E9E9';
            value2.evento_nome == 'Liberar'    ? value2.evento_cor = '#f6f6c0' : '#E9E9E9';
            return value2;
          })
          return { descricao: value.empreend_nome, cod_empreendimento: value.cod_empreendimento, items: empreends_evento};
        })
      }
      return response2;
    },

    // Busca os privilegios do evento específico de empreendimento para o usuário logado
    priv_busca_privilegios_empreend_evento(p_CodEmpreendimento, p_EventoNome) {
      // Administrador
      if (this.user.pessoa_funcao.find(o => (o.cod_funcao == 5)))
        return true;

      // Outros Tipos de atribuções
      let p_elemento = null;
      if (this.user.pessoa_privilegio_empreendimento && this.user.pessoa_privilegio_empreendimento.length > 0)
        p_elemento = this.user.pessoa_privilegio_empreendimento.find((o) => (o.cod_empreendimento == p_CodEmpreendimento && o.evento_nome == p_EventoNome));
      return p_elemento ? true : false;
    },

    // ========== PAINEIS ==========================================================================
    // Busca todos os eventos de todos os Paineis
    async busca_paineis() {
      let response2 = [];
      const response = await API.get("/configuracao/painel_evento", {});
      if (response.data.result.length > 0) {
        response2 = response.data.result.map(value => {
          let paineis_evento = value.painel_evento.map(value2 => {
            value2.cod_painel_evento   = value2.cod_painel_evento;
            value2.evento_descricao    = value2.evento_nome;
            value2.id_evento           = value2.id_evento;
            if (!value2.evento_cor)
              value2.evento_cor = '#E9E9E9';
            value2.evento_nome == 'Visualizar' ? value2.evento_cor = '#bbefef' : '#E9E9E9';
            value2.evento_nome == 'Incluir'    ? value2.evento_cor = '#85d7bc' : '#E9E9E9';
            value2.evento_nome == 'Alterar'    ? value2.evento_cor = '#f0cf90' : '#E9E9E9';
            value2.evento_nome == 'Excluir'    ? value2.evento_cor = '#e1acac' : '#E9E9E9';
            value2.evento_nome == 'Liberar'    ? value2.evento_cor = '#f6f6c0' : '#E9E9E9';
            return value2;
          })
          return { menu_nivel1: value.menu_nivel1, descricao: value.nome, cod_painel: value.cod_painel, items: paineis_evento};
        })
      }
      return response2;
    },

    // Busca os privilegios do evento específico de paineis para o usuário logado
    priv_busca_privilegios_painel_evento(p_idEvento) {
      // Administrador
      if (this.user.pessoa_funcao.find(o => (o.cod_funcao == 5)))
        return true;

      // Outros Tipos de atribuções
      let p_elemento = null;
      if (this.user.pessoa_privilegio_painel && this.user.pessoa_privilegio_painel.length > 0)
        p_elemento = this.user.pessoa_privilegio_painel.find((o) => (o.id_evento == p_idEvento));
      return p_elemento ? true : false;
    },

    // ========== RELATORIOS ==========================================================================
    // Busca todos os eventos de todos os Relatorios
    async busca_relatorios() {
      let response2 = [];
      const response = await API.get("/configuracao/relatorio_evento", {});
      if (response.data.result.length > 0) {
        response2 = response.data.result.map(value => {
          let relatorios_evento = value.relatorio_evento.map(value2 => {
            value2.cod_relatorio_evento   = value2.cod_relatorio_evento;
            value2.evento_descricao       = value2.evento_nome;
            value2.id_evento              = value2.id_evento;
            if (!value2.evento_cor)
              value2.evento_cor = '#E9E9E9';
            value2.evento_nome == 'Visualizar' ? value2.evento_cor = '#bbefef' : '#E9E9E9';
            value2.evento_nome == 'Incluir'    ? value2.evento_cor = '#85d7bc' : '#E9E9E9';
            value2.evento_nome == 'Alterar'    ? value2.evento_cor = '#f0cf90' : '#E9E9E9';
            value2.evento_nome == 'Excluir'    ? value2.evento_cor = '#e1acac' : '#E9E9E9';
            value2.evento_nome == 'Liberar'    ? value2.evento_cor = '#f6f6c0' : '#E9E9E9';
            return value2;
          })
          return { menu_nivel1: value.menu_nivel1, descricao: value.nome, cod_relatorio: value.cod_relatorio, items: relatorios_evento};
        })
      }
      return response2;
    },

    // Busca os privilegios do evento específico de relatorios para o usuário logado
    priv_busca_privilegios_relatorio_evento(p_idEvento) {
      // Administrador
      if (this.user.pessoa_funcao.find(o => (o.cod_funcao == 5)))
        return true;

      // Outros Tipos de atribuções
      let p_elemento = null;
      if (this.user.pessoa_privilegio_relatorio && this.user.pessoa_privilegio_relatorio.length > 0)
        p_elemento = this.user.pessoa_privilegio_relatorio.find((o) => (o.id_evento == p_idEvento));
      return p_elemento ? true : false;
    },

  }

 
})

export default usuario
