import { API, API_BLOB, baseURL } from "./API";
import { Capacitor } from "@capacitor/core";
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { moment } from "moment";

import store_usuario from "../store/store_usuario"
import store_Login from "../components/Login/store_Login"
import store_Usuarios from "../components/Usuarios/store_Usuarios"
import store_Proposta from "../components/Propostas/store_Proposta"
import { blobToBase64, getRandomInt, loadScript } from "./funcoes"

var GS_APLICACAO_INICIAL = 'obraconsulta';

const Email = async (body) => {
  const resp = await API.post(`email`, JSON.stringify(body));
  const { data } = resp;
  return data;
};

const BancosGet = async (p_params) => {
  const resposta = await API.get("banco", p_params);
  var ls_Resp = [];
  if (resposta)
    if (resposta.data.result != 'Não possui dados')
      ls_Resp = resposta.data.result;
  return ls_Resp;
}

const ProspeccaoGet = async () => {
  const resposta = await API.get("prospeccao", {});
  var ls_Resp = [];
  if (resposta)
    if (resposta.data.result != 'Não possui dados')
      ls_Resp = resposta.data.result;
  return ls_Resp;
}

const FuncoesGet = async () => {
    const resposta = await API.get("funcao", {});
    var ls_Resp = [];
    if (resposta)
      if (resposta.data.result != 'Não possui dados')
        ls_Resp = resposta.data.result;
    return ls_Resp;
}

const ListaRelatoriosGet = async () => {
  const resp = await API.get(`lista_relatorios`, {} );
    var la_Resp = [];
    la_Resp = resp.data.result;
    //console.log("la_Resp", la_Resp)
    return la_Resp;
}

const ListaPaineisGet = async () => {
  const resp = await API.get(`lista_paineis`, {} );
    var la_Resp = [];
    la_Resp = resp.data.result;
    return la_Resp;
}

const ListaPaineisUsuarioGet = async () => {
  const resp = await API.get(`lista_paineis_usuario`, {} );
    var la_Resp = [];
    la_Resp = resp.data.result;
    return la_Resp;
}

const MenuRelatorioGet = async (p_programa) => {
  const resp = await API.get(`configuracao/menu_relatorio`, {params: {programa: p_programa}} );
  var la_Resp = [];
  if (resp && resp?.data?.result.length > 0) {
    la_Resp = resp.data.result[0].menu;
    la_Resp = eval('(' + la_Resp + ')');
  }
  return la_Resp;
}

const MenuPrincipalGet = async (p_cod_empresa) => {
  var resp = await API.get(`configuracao/menu_config`, {params: {cod_empresa: p_cod_empresa}} );
  var lo_resp = resp.data.result;
  return lo_resp;
}

const SituacoesGet = async () => {
  const resposta = await API.get("situacoes", {});
  var ls_Resp = [];
  if (resposta)
      if (resposta.data.result != 'Não possui dados')
          ls_Resp = resposta.data.result;
  return ls_Resp;
}

const RelatorioPDF = async (body) => {
  if (body.tipo == "html") {
    //console.log("HTML 222222 ", body.tipo);
    const resp = await API_BLOB.post(`relatorio`, JSON.stringify(body));
    const { data } = resp;
    const file = new Blob([data], { type: "text/html" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }
}

const Relatorio = async (body) => {
  // if (body.tipo == "pdfME") {
  //   const resp = await API_BLOB.post(`relatorio_pdfme`, JSON.stringify(body));
  //   const resp_text = await resp.data.text();
  //   const resp_JSON = JSON.parse(resp_text)
  //   const template = resp_JSON.result.data;
  //   const inputs   = body.inputs;
  //   const pdf = await generate({ template, inputs });
  //   if (body.mail && body.mail.to) {
  //   //   // Enviando email
  //   //   const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
  //   //   const pdfBase64 = await blobToBase64(blob);
  //   //   const mailOptions = { email       : body.mail.to,
  //   //                         subject     : body.mail.subject,
  //   //                         attachments : [{
  //   //                                         filename  : 'Boleto.pdf',
  //   //                                         content   : pdfBase64.replace("data:application/pdf;base64,",""),
  //   //                                         encoding  : 'base64'
  //   //                                       }]
  //   //                         }
  //   //   const ls_RespEmail = await Email(mailOptions);
  //   //   if (ls_RespEmail.result.message == 'success')
  //   //     console.log('Email Enviado:', ls_RespEmail.result.message)
  //   //   else
  //   //     console.log('Email NAO Enviado:', ls_RespEmail)
  //   }
  //   else {
  //     // Node.js
  //     // fs.writeFileSync(path.join(__dirname, 'test.pdf'), pdf);

  //     // Browser
  //     const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
  //     window.open(URL.createObjectURL(blob));
  //   }

  // }
  // else
  if (body.tipo == "pdf" || body.tipo == "html-pdf") {

      let resp = null;
      resp = await API_BLOB.post(`relatorio`, JSON.stringify(body));

      const { data } = resp;

      // Abrindo no Browser ou no Android ou no IOS
      //const { Filesystem } = Plugins;
      const pdfBase64 = await blobToBase64(data);

      if (Capacitor.getPlatform() === 'android') {
          // Save the PDF to the device
//alert('00000000000.0::' + moment().format())

// Verificar se tem de mudar o nome do aquivo para corrigir problema do Android 11
//https://stackoverflow.com/questions/67723322/capacitor-filesystem-returns-error-while-using-main-functions
          //const fileName = 'YounectImob_' + moment().format('YYYY-MM-DD_HH-mm-ss-SSS') + '.pdf';
          //const file = new Blob([data], { type: "application/pdf" });
          const fileName = 'YounectImob_' + getRandomInt(1000000) + '.pdf';
//alert('0000000000:: ' + fileName);
          try {
              //data:application/pdf;base64,IGHGT&5&65ioYytopyur23@6...
              const pdfBase64_Limpo = pdfBase64.split(',')[1];
//alert('11111111111')
              const ff = await Filesystem.writeFile({ path        : fileName,
                                                      data        : pdfBase64_Limpo,
                                                      directory   : Directory.Documents,
                                                      //encoding    : Encoding.UTF8,   //this line fixes writeFile but breaks share
                                                      recursive   : true
                                                   });
//alert('22222222222')
              const getUriResult = await Filesystem.getUri({
                                                              directory   : Directory.Documents,
                                                              path        : fileName
                                                          });
//alert('3333333333')
              const path = getUriResult.uri;
//alert('44444444444')
              try {
                const ls_Share = await Share.share({
                  title       : 'Younect-Imob',
                  text        : 'Olá, você acaba de receber um documento.',
                  url         : path,
                  dialogTitle : store_usuario.user.pessoa_nome,
                });
//alert('555555555555::' + ls_Share);
                store_site.alert = false;
                return pdfBase64;
              } catch (err) {
                return err;
              }              
          } catch (error) {
            store_site.alert = false;
            store_site.alert_msg = 'Falha ao salvar arquivo: ' + error;
            store_site.alert = true;
          }
      }
      else if (Capacitor.getPlatform() === 'web') {
          // On a browser simply use download
          const file = new Blob([data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
      }

      //window.open(fileURL);

      // blobToBase64(data).then(res => {
      //     // do what you wanna do
      //     //console.log(res); // res is base64 now
      //     let pdfWindow = window.open("")
      //     pdfWindow.document.write(
      //         "<iframe width='100%' height='100%' src='" + encodeURI(res) + "'></iframe>"
      //     )
      // });

      return pdfBase64;
  }
  else if (body.tipo == "html") {
      //console.log("HTML 222222 ", body.tipo);
      const resp = await API_BLOB.post(`relatorio`, JSON.stringify(body));
      const { data } = resp;
      const file = new Blob([data], { type: "text/html" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
  }
  else if (body.tipo == "json_conteudo") {
      //console.log("HTML 3333 ", body.tipo);
      const resp = await API.post(`relatorio`, JSON.stringify(body));
      //console.log("HTML 555555 ", resp);
      return resp.data.relatorio;
  }
}

const Painel = async (body) => {
  if (body.tipo == "pdf" || body.tipo == "html-pdf") {

      // let resp = null;
      // resp = await API_BLOB.post(`relatorio`, JSON.stringify(body));

      // const { data } = resp;

      // // Abrindo no Browser ou no Android ou no IOS
      // const pdfBase64 = await blobToBase64(data);

      // if (Capacitor.getPlatform() === 'android') {
      //     // Save the PDF to the device
      //     const fileName = 'YounectImob_' + getRandomInt(1000000) + '.pdf';
      //     try {
      //         const pdfBase64_Limpo = pdfBase64.split(',')[1];
      //         const ff = await Filesystem.writeFile({ path        : fileName,
      //                                                 data        : pdfBase64_Limpo,
      //                                                 directory   : Directory.Documents,
      //                                                 //encoding    : Encoding.UTF8,   //this line fixes writeFile but breaks share
      //                                                 recursive   : true
      //                                              });
      //         const getUriResult = await Filesystem.getUri({
      //                                                         directory   : Directory.Documents,
      //                                                         path        : fileName
      //                                                     });
      //         const path = getUriResult.uri;
      //         try {
      //           const ls_Share = await Share.share({
      //             title       : 'Younect-Imob',
      //             text        : 'Olá, você acaba de receber um documento.',
      //             url         : path,
      //             dialogTitle : store_usuario.user.pessoa_nome,
      //           });
      //           store_site.alert = false;
      //           return pdfBase64;
      //         } catch (err) {
      //           return err;
      //         }              
      //     } catch (error) {
      //       store_site.alert = false;
      //       store_site.alert_msg = 'Falha ao salvar arquivo: ' + error;
      //       store_site.alert = true;
      //     }
      // }
      // else if (Capacitor.getPlatform() === 'web') {
      //     // On a browser simply use download
      //     const file = new Blob([data], { type: "application/pdf" });
      //     const fileURL = URL.createObjectURL(file);
      //     window.open(fileURL);
      // }

      // return pdfBase64;
  }
  else if (body.tipo == "html") {
      // const resp = await API_BLOB.post(`relatorio`, JSON.stringify(body));
      // const { data } = resp;
      // const file = new Blob([data], { type: "text/html" });
      // const fileURL = URL.createObjectURL(file);
      // window.open(fileURL);
  }
  else if (body.tipo == "json_conteudo") {
      const resp = await API.post(`painel`, JSON.stringify(body));
      return resp.data.result.painel;
  }
}

const Zap = async (body, telefone) => {
  if (body.tipo == "pdf" || body.tipo == "html-pdf") {
      // Nova API servidor porta 9000 com whatsapp-web plugin
      body.whatsapp = {};
      body.whatsapp.caption   = "ARQUIVO";
      body.whatsapp.number    = telefone;
      body.whatsapp.filename  = "Simulação.pdf";
      body.whatsapp.mimetype  = "application/pdf";
      const resp = await API.post(`relatorio`, JSON.stringify(body));
      return resp;
      //console.log('++++++ ', resp); // res is base64 now

      //const file = new Blob([data], { type: "application/pdf" });
      //const fileURL = URL.createObjectURL(file);
      //window.open('https://wa.me/' + telefone + '?text= data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAMAAABFaP0WAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAAZQTFRFAAAzAAAAR/nugAAAAA9JREFUeNpiYGQAAoAAAwAACwACPoZq/wAAAABJRU5ErkJggg==');
      //window.open('https://wa.me/' + telefone + '?text=testando https://farm8.staticflickr.com/7090/6941316406_80b4d6d50e_z_d.jpg');

      // Enviando diretamente para o Zao da Pessoa, agora será enviado pelo Backend
      //const { data } = resp;
      // blobToBase64(data).then(res => {
      //     // console.log('++++++ ', typeof res,  res); // res is base64 now
      //     //     // do what you wanna do
      //     //     let pdfWindow = window.open("")
      //     //     pdfWindow.document.write(
      //     //         "<iframe width='100%' height='100%' src='https://wa.me/" + telefone + "?" + 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAMAAABFaP0WAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAAZQTFRFAAAzAAAAR/nugAAAAA9JREFUeNpiYGQAAoAAAwAACwACPoZq/wAAAABJRU5ErkJggg==' + "'></iframe>"
      //     //     )

      //     var lo_JSON = { caption   : "ARQUIVO",
      //                     number    : telefone,
      //                     filename  : "Simulação.pdf",
      //                     mimetype  : "application/pdf",
      //                     base64    : res
      //                    }
      //     async function enviar_zap() {
      //         console.log("enviar_zap");
      //         const resp = await API_ZAP.post(`sendMedia`, JSON.stringify(lo_JSON));
      //         return resp;
      //     }
      //     enviar_zap();
      // });

      //window.open(`https://api.whatsapp.com/send?phone=${this.country.default.callingCode}${this.phoneNumber}&text=${this.message.text}`,_blank");
      //console.log("fileURL 2", telefone, fileURL);
  }
  else if (body.tipo == "html") {
      const resp = await API_BLOB.post(`relatorio`, JSON.stringify(body));
      const { data } = resp;
      const file = new Blob([data], { type: "text/html" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
  }
}

const OrcamentoRelatorio = async (cod_orcamento) => {
  let data = {
    nome: "VENDA.ORCAMENTO",
    tipo: "html-pdf",
    parametros: [{ Name: "cod_orcamento", Value: cod_orcamento }],
  };
  const ls_Resp = await Relatorio(data);
}

const ReservaRelatorio = async (cod_opor) => {
  let data = {
    nome: "VENDA.RESERVA",
    tipo: "html-pdf",
    parametros: [{ Name: "cod_opor", Value: cod_opor }],
  };
  const ls_Resp = await Relatorio(data);
}

const PropostaRelatorio = async (cod_opor, assunto_email) => {
  let data = {
    nome: "VENDA.PROPOSTA",
    tipo: "pdf",
    parametros: [{ Name: "cod_opor", Value: cod_opor }],
  };
  const ls_Resp = await Relatorio(data);

  if (assunto_email) {
    // Enviando email da Proposta para os Gestores e o Corretor que vendeu
    const ls_Resp2 = ls_Resp.replace("data:application/pdf;base64,","");
    const la_Email = [];
    const ls_Email = await store_Proposta.PropostaEmails(cod_opor); // Adicionar o corretor e os gestores na lista de emails
    ls_Email.map((value) => {
      la_Email.push(value.email);
    })
    const mailOptions = { email       : la_Email,
                          subject     : assunto_email,
                          attachments : [{
                                          filename  : 'Proposta.pdf',
                                          content   : ls_Resp2,
                                          encoding  : 'base64'
                                        }]
                          }
//console.log('Enviando email:', mailOptions);
    const ls_RespEmail = Email(mailOptions);
  }

}

const AbreRelatorioExterno = async (nome, params) => {

  let parametros = null;
  parametros = [{ Name: "cod_usuario", Value: store_usuario.user.cod_usuario }];
  if (params) {
    if (Array.isArray(params)) {
      for (let index = 0; index < params.length; index++) {
        parametros.push(params[index]);        
      }
    }
    else
      parametros.push(params);
  }
    
  let body = {
    nome: nome,
    tipo: "json_conteudo",
    parametros : [...parametros]
  };

    // Relatório com Window Novo (WindowPortal)
    //this.open = true;

    // Relatorio com componente Vue ------------------------------------------
    //this.relatorio = await Relatorio(body);
    //this.dialog_relatorio = true;
    //console.log("ENVIANDO", this.relatorio)

    //this.$router.push({ name: "Rel1", params: {relatorio: this.relatorio}, target:"_blank" }, );

    //this.loading = 0;
    //const resp = await API.get(`relatorio`, JSON.stringify(body));

    // const resp = await API_REL_BASE.post(`index2`, body);
    // const { data } = resp;
    // var newWindow = window.open();
    // newWindow.document.body.innerHTML = data;

    //this.$router.push({redirect: window.location.href = baseURL + 'rel', })

    // const file = new Blob([data], { type: "text/plain" });
    // const fileURL = URL.createObjectURL(file);
    //window.open(fileURL);

    // https://stackoverflow.com/questions/64966554/creating-a-blob-from-unicodes-code-points-in-javascript

    // var newWindow = window.open();
    // newWindow.document.write("<iframe src='" + fileURL + "' frameborder='0' ></iframe>");
    // newWindow.document.close();
    // console.log("<iframe v-bind:src='" + window.URL.createObjectURL(file) + "' frameborder='0' ></iframe>");

    //var blob = new Blob([data], { type: "text/html;charset=UTF-8" });
    //var url = URL.createObjectURL(blob);
    //document.getElementById("nav").href = url;
    //document.getElementById("i-frame").src= url;

    //var b = new Blob([data],{encoding:"UTF-8",type:"text/plain;charset=UTF-8"});
    //var url = URL.createObjectURL(b);
    //window.open(url,"_blank","");

    //var newWindow = window.open();
    //newWindow.document.write(data);
    //newWindow.document.close();

    //this.url_relatorio = API_REL_BASE.post(`index2`, body);
    //var newWindow = window.open();
    //newWindow.document.location.href = this.url_relatorio;

    ///window.location.href = baseURL + 'rel';
    ///this.mostra = true;
    ///this.$router.push("/indexrelatorio")
    //document.write(this.url_relatorio);
    //documento.close();

    //this.url_relatorio = baseURL + 'index2' + '?cod_relatorio=2';
    //var newWindow = window.open();
    //newWindow.document.location.href = this.url_relatorio;

    // Create request object
    // var request = new Request(baseURL + 'index2',
    //     { method: 'POST',
    //       body: {'name': 'Klaus'},
    //       //headers: new Headers({ 'Content-Type': 'application/json' })
    //     });
    // // Now use it!
    // fetch(request)
    // .then(resp => {
    //   console.log('XXXXXXX', resp);
    //     // handle response })
    // })
    // .catch(err => {
    //   console.log(err);
    //     // handle errors
    // });

    // const resposta = await API.post("rel", {
    //                                         params: {
    //                                           teste: 2
    //                                         },
    //                                       }
    //                               );
    // if (resposta) {
    //   console.log("resposta", resposta)
    // }

    //this.url_relatorio = baseURL + 'rel';
    //var newWindow = window.open();
    //newWindow.document.location.href = this.url_relatorio;

    // var redirect = function(url, method) {
    //   console.log("ENTROU !!!")
    //     var form = document.createElement('form');
    //     form.method = method;
    //     form.action = url;
    //     form.submit();
    // };
    // redirect('http://www.example.com', 'post');

    window.io = {
      open: function(verb, url, data, target) {
        var form = document.createElement("form");
        form.action = url;
        form.method = verb;
        //form.target = target || "_self";
        if (target)
          form.target = target;
        if (data) {
            for (var key in data) {
                var input = document.createElement("textarea");
                input.name = key;
                input.value = typeof data[key] === "object"
                    ? JSON.stringify(data[key])
                    : data[key];
                form.appendChild(input);
            }
        }
        //form.style.display = 'none';
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
      }
    }
	// Se não tiver token definido, passar somente um token com os parametrôes deste site
	let token = localStorage.getItem("@YouNect-Imob-" + window.location.hostname + ":token");
  await io.open('POST', baseURL + 'relatorio_externo', { token : token, body: body }, 'i_frame');
}

const AbrePainelExterno = async (nome, params) => {
  let parametros = null;
  parametros = [{ Name: "cod_usuario", Value: store_usuario.user.cod_usuario }];
  if (params) {
    if (Array.isArray(params)) {
      for (let index = 0; index < params.length; index++) {
        parametros.push(params[index]);        
      }
    }
    else
      parametros.push(params);
  }
    
  let body = {
    nome: nome,
    tipo: "json_conteudo",
    parametros : [...parametros]
  };

    window.io = {
      open: function(verb, url, data, target) {
        var form = document.createElement("form");
        form.action = url;
        form.method = verb;
        //form.target = target || "_self";
        if (target)
          form.target = target;
        if (data) {
            for (var key in data) {
                var input = document.createElement("textarea");
                input.name = key;
                input.value = typeof data[key] === "object"
                    ? JSON.stringify(data[key])
                    : data[key];
                form.appendChild(input);
            }
        }
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
      }
    }
	// Se não tiver token definido, passar somente um token com os parametrôes deste site
	let token = localStorage.getItem("@YouNect-Imob-" + window.location.hostname + ":token");
  await io.open('POST', baseURL + 'painel_externo', { token : token, body: body }, 'i_frame');
}

const DestroyStores = async (nome) => {
  // console.log('destruindo Stores')
  store_Login.resetData();
  // console.log('store_Login');
  store_Usuarios.resetData();
  // console.log('store_Usuarios');
/*
////++++
  store_Empreendimentos.resetData();
  // console.log('store_Empreendimentos');
  store_EmpreendMapa.resetData();
  // console.log('store_EmpreendMapa');
  store_Negociacoes.resetData();
  // console.log('store_Empreendimentos');
  store_ModalNegociacao.resetData();
  // console.log('store_Negociacoes');
  store_Proposta.resetData();
  // console.log('store_Proposta');
  store_Pessoa.resetData();
  // console.log('store_Pessoa');
  store_Corretor.resetData();
  // console.log('store_Corretor');
  store_Contato.resetData();
  // console.log('store_Contato');
  store_Simulacoes.resetData();
  // console.log('store_Contato');
  store_Propostas.resetData();
  // console.log('store_Contato');
////++++
*/
}

// Verifica se o Device da Pessoa é realmente o aparelho celular Android ou IOS
const isMobileDevice = async () => {
  try {
    // console.log('++++++++++++++++++');
    const lb_Return = await (Capacitor.getPlatform() === 'android' ||
                             Capacitor.getPlatform() === 'ios')
    return lb_Return
  }
  catch (e) {
    return false
  }
}

const IniciaStimulsoft = async() => {
  if (!window.Stimulsoft) {
    //console.log('GERANDO window.Stimulsoft', window.Stimulsoft)
    const scripts = [
      './sti-2023/scripts/stimulsoft.reports.js',
      './sti-2023/scripts/stimulsoft.viewer.js',
      './sti-2023/scripts/stimulsoft.designer.js',
      './sti-2023/scripts/stimulsoft.dashboards.js',
    ];

    Promise.all(scripts.map(src => loadScript(src)))
      .then(() => {
        //console.log('Todos os scripts foram carregados com sucesso', window.Stimulsoft, ' - ', stimulsoft);
        // Aqui você pode inicializar ou utilizar o objeto `stimulsoft`
        /*
        ////++++
        Stimulsoft.Base.Localization.StiLocalization.addLocalizationFile("sti - 2020/Localizations/pt-BR.xml", false, "Portuguese (Brazil)");
        Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile("sti - 2020/Localizations/pt-BR.xml", true);
        Stimulsoft.StiOptions.Export.Pdf.WinFormsHighAccuracyWordWrap = true;
        Stimulsoft.StiOptions.Export.Pdf.reduceFontFileSize = true;
        Stimulsoft.StiOptions.Export.Pdf.allowEditablePdf = false;
        Stimulsoft.StiOptions.Export.Pdf.useAlternativeFontNames = false;
        Stimulsoft.StiOptions.Export.Pdf.allowImportSystemLibraries = true;
        Stimulsoft.StiOptions.Export.Pdf.AllowImportSystemLibraries = true;
        Stimulsoft.StiOptions.Export.Pdf.allowFontsCache = false;
        Stimulsoft.StiOptions.allowImportSystemLibraries = true;
        Stimulsoft.StiOptions.AllowImportSystemLibraries = true;
        // Roboto Black
        // Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/Roboto-Black.ttf");
        // Arial
        Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/arial.ttf");
        Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/arialbd.ttf");
        Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/arialbi.ttf");
        Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/ariali.ttf");
        Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/ARIALN.TTF");
        Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/ARIALNB.TTF");
        Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/ARIALNBI.TTF");
        Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/ARIALNI.TTF");
        Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/ariblk.ttf");
        Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/ARLRDBD.TTF");
        // Calibri
        Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/calibri.ttf");
        Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/calibrib.ttf");
        Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/calibrii.ttf");
        Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/calibril.ttf");
        Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/calibrili.ttf");
        Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/calibriz.ttf");
        // SegoeUI
        Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/segoeui.ttf");
        Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/segoeuib.ttf");
        Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/segoeuii.ttf");
        Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/segoeuil.ttf");
        Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/segoeuisl.ttf");
        Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("sti - 2020/fonts/segoeuiz.ttf");
        // Stimulsoft a partir de 2022 -------------------------------------------------------------------------------------------
        // Stimulsoft.Base.StiFontCollection.setOpentypeFontsFolder("sti - 2020/fonts/");
        ////++++
        */

        // Stimulsoft 2023 ===============================================================================================================
        //window.Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHkcgIvwL0jnpsDqRpWg5FI5kt2G7A0tYIcUygBh1sPs7plofUOqPB1a4HBIXJB621mau2oiAIj+ysU7gKUXfjn/D5BocmduNB+ZMiDGPxFrAp3PoD0nYNkkWh8r7gBZ1v/JZSXGE3bQDrCQCNSy6mgby+iFAMV8/PuZ1z77U+Xz3fkpbm6MYQXYp3cQooLGLUti7k1TFWrnawT0iEEDJ2iRcU9wLqn2g9UiWesEZtKwI/UmEI2T7nv5NbgV+CHguu6QU4WWzFpIgW+3LUnKCT/vCDY+ymzgycw9A9+HFSzARiPzgOaAuQYrFDpzhXV+ZeX31AxWlnzjDWqpfluygSNPtGul5gyNt2CEoJD1Yom0VN9fvRonYsMsimkFFx2AwyVpPcs+JfVBtpPbTcZscnzUdmiIvxv8Gcin6sNSibM6in/uUKFt3bVgW/XeMYa7MLGF53kvBSwi78poUDigA2n12SmghLR0AHxyEDIgZGOTbNI33GWu7ZsPBeUdGu55R8w="

        // Stimulsoft 2023 ===============================================================================================================
        //console.log('BASSSSSSSSSSSSSSSE', window.Stimulsoft);
        window.Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHkgpgFGkUl79uxVs8X+uspx6K+tqdtOB5G1S6PFPRrlVNvMUiSiNYl724EZbrUAWwAYHlGLRbvxMviMExTh2l9xZJ2xc4K1z3ZVudRpQpuDdFq+fe0wKXSKlB6okl0hUd2ikQHfyzsAN8fJltqvGRa5LI8BFkA/f7tffwK6jzW5xYYhHxQpU3hy4fmKo/BSg6yKAoUq3yMZTG6tWeKnWcI6ftCDxEHd30EjMISNn1LCdLN0/4YmedTjM7x+0dMiI2Qif/yI+y8gmdbostOE8S2ZjrpKsgxVv2AAZPdzHEkzYSzx81RHDzZBhKRZc5mwWAmXsWBFRQol9PdSQ8BZYLqvJ4Jzrcrext+t1ZD7HE1RZPLPAqErO9eo+7Zn9Cvu5O73+b9dxhE2sRyAv9Tl1lV2WqMezWRsO55Q3LntawkPq0HvBkd9f8uVuq9zk7VKegetCDLb0wszBAs1mjWzN+ACVHiPVKIk94/QlCkj31dWCg8YTrT5btsKcLibxog7pv1+2e4yocZKWsposmcJbgG0"
        window.Stimulsoft.Base.Localization.StiLocalization.addLocalizationFile("sti-2023/Localizations/pt-BR.xml", false, "Portuguese (Brazil)");
        window.Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile("sti-2023/Localizations/pt-BR.xml", true);
        window.Stimulsoft.Base.StiFontCollection.setOpentypeFontsFolder("sti-2023/fonts/");
        window.StiOptions.WebServer.url = baseURL;
      })
      .catch(error => {
        console.error('Erro ao carregar os scripts:', error);
      });
  }
}

export {
    Email,
    BancosGet,
    ProspeccaoGet,
    FuncoesGet,
    SituacoesGet,
    ListaRelatoriosGet,
    ListaPaineisGet,
    ListaPaineisUsuarioGet,
    MenuRelatorioGet,
    MenuPrincipalGet,
    DestroyStores,
    isMobileDevice,

    Relatorio,
    Painel,
    Zap,
    AbreRelatorioExterno,
    AbrePainelExterno,
    OrcamentoRelatorio,
    ReservaRelatorio,
    PropostaRelatorio,

    RelatorioPDF,

    GS_APLICACAO_INICIAL,

    IniciaStimulsoft

}
